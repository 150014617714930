import registry from 'blocks/registry';

const LOGIN_COUNTER_KEY = 'entrystore_login_attempt';

/**
 * Counter for login attempts to avoid loop on success but fail to get user
 *
 * @returns {number}
 */
export const loginAttempts = () => {
  const loginStatus = sessionStorage.getItem(LOGIN_COUNTER_KEY) || '0';
  return parseInt(loginStatus, 10);
};

/**
 * Make attempt to logg in with SSO (saml)
 */
export const trySamlLogin = () => {
  sessionStorage.setItem(LOGIN_COUNTER_KEY, `${loginAttempts() + 1}`);
  const entrystore = registry.get('entrystore');
  const encodedURL = encodeURIComponent(window.location.href);
  window.location.href = `${entrystore.getBaseURI()}auth/saml?successurl=${encodedURL}`;
};

/**
 * Check the sso path
 *
 * @param {string} path
 * @returns {boolean}
 */
const isValidSSOPath = (path) => {
  return path === 'saml' || path === 'multi';
};

/**
 * Try getting the userId with active credentials
 * Will disable credentials if server returns error
 *
 * @param {string} path
 * @returns {Promise<boolean>}
 */
export const checkIfShouldLogin = async (path) => {
  if (!isValidSSOPath(path)) return false;
  const entrystore = registry.get('entrystore');
  try {
    entrystore.getREST().enableCredentials();
    const currentUserEntry = await entrystore.getAuth().getUserEntry();
    const userId = currentUserEntry.getId();
    return userId === '_guest';
  } catch (error) {
    console.error('On request for auth/user with credentials', error);
    entrystore.getREST().disableCredentials();
    return false;
  }
};

/**
 * Stop tracking login attempts
 */
export const endSamlLogin = () => {
  sessionStorage.removeItem(LOGIN_COUNTER_KEY);
};
