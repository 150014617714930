import config from 'config';
import commonsDefaults from './commonDefaults'; // TODO HACK this needs to be before '../config/site' in order for namespaces to be set in registry
import Blocks from './boot/block';

export default async () => {
  await commonsDefaults();

  // Initialize blocks automatically only if not a SPA.
  if (!config.econfig.spa) {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', Blocks.init);
    } else {
      Blocks.init();
    }
  }
  window.__entryscape_blocks = Blocks;
  if (window.__entryscape_blocks_resolve) {
    window.__entryscape_blocks_resolve();
  }
}
